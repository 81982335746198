<template>
<div class="chart" >
    <el-card class="post" v-loading="loading">
        <span>发帖类型偏好占比</span>
        <el-button class="primary-plain" plain size="small" @click="onExport(start_type.post)">导出</el-button>
      <EChart class="echart" :charDatastart="post"></EChart>
    </el-card>
    <el-card class="comment" v-loading="loading">
      <span>评论类型偏好占比</span>
      <el-button class="primary-plain" plain size="small" @click="onExport(start_type.comment)">导出</el-button>
      <EChart class="echart" :charDatastart="comment"></EChart>
    </el-card>
</div>

</template>

<script>
import { chartData, chartExport } from '../../api/group/preference-chart'
import EChart from '../../components/group/EChart.vue'
  export default {
    name: 'home',
    components: {
      EChart
    },
    data() {
      return {
        start_type: {
          post: 0,
          comment: 1,
        },
        comment: [],
        post: [],
        loading: false
      }
    },
    methods: {
      // 获取数据
      getChartData() {
        this.loading = true
        chartData({id: this.$route.params.id}).then(res => {
          const { data } = res
          this.loading = false
          this.post = data.post.map(({label, count}) => ({ name: label, value: count }))
          this.comment = data.comment.map(({label, count}) => ({ name: label, value: count}))
        }).catch(err => this.loading = false ) 
      },
      // 导出
      onExport(data) {
        return chartExport({id: this.$route.params.id, stat_type: data, })
        .then()
        .catch(err => console.log(err))
      }
    },
    created() {
      this.getChartData()
    }
  }
</script>

<style lang="scss" scoped>
.chart {
  display: flex;
  .post {
    height: 350px;
    .primary-plain {
      float: right
    }
    .echart {
      height: 280px;
      width: 400px
    }
  }
  .comment {
    margin-left: 30px;
    height: 350px;
    .primary-plain {
      float: right
    }
    .echart {
      height: 280px;
      width: 400px
    }
  }
}
</style>