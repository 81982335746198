import api from '@/base/utils/request'
import download from '@/base/utils/download'

// 列表
export const chartData = data => {
    return api({
        url: '/admin/circle/GroupInteract/interactStat',
        method: 'post',
        data
    })
}

// 导出
export const chartExport = data => {
    return download({
        url: '/admin/circle/GroupInteract/interactStatExport',
        method: 'post',
        data,
        download: true
    })
}
